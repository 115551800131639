.list-payment {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -0.5rem 0;
  padding-top: 1rem;
  padding-left: 0;
}

@media screen and (min-width: 750px) {
  .list-payment {
    justify-content: flex-end;
    margin: -0.5rem;
    padding-top: 0;
  }
}

.list-payment__item {
  align-items: center;
  display: flex;
  padding: 0.5rem;
}
